<template>
    <div v-scrollanimation class="my-footer text-size-14px">
        <div class="footer"></div>
        Copyright © {{ currentYear }} Hamza El Alaoui
    </div>
</template>

<script>
export default { 
    name: "Footer",
    data() {
        return {
            currentYear: new Date().getFullYear()
        }
    },
    methods: {
        openSite(site) {
            window.open(site,'_blank')
        }
    }
};
</script>
