<template>
    <AboutMeSection /> 
 
</template>
<script>
import AboutMeSection from './../../components/AboutMe/ABoutMeSection.vue';
 
 export default {
    components: {
        AboutMeSection     
    },
};
</script> 