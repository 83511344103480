
export const socialMediaLinks = [
    
    {
        icon: `<i class='bx bxl-linkedin' ></i>`,
        title: "LinkedIn",
        link: 'https://www.linkedin.com/in/hamzaelalaoui/'
    },
    

    {
        icon: `<i class='bx bxl-github' ></i>`,
        title: "GitHub",
        link: 'https://github.com/hamzaeIalaoui'
    },

    {

        icon: `<i class='bx bxs-google' ></i>`,
        title: "Google Scholar",
        link: 'https://scholar.google.com/citations?user=9PBfhTkAAAAJ&hl=en',
    },
    

    {

    icon: `<i class='bx bxl-facebook' ></i>`,
    title: "Facebook",
    link: 'https://www.facebook.com/hamzaeIalaoui',
},
{

    icon: `<i class='bx bxs-youtube' ></i>`,
    title: "Youtube",
    link: 'https://www.youtube.com/@hamzaeIalaoui',
}


 
];

export const email = "hamzaelalaoui[at]ieee[dot]org";