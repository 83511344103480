<template>
    <div class="theme-toggle" @click="toggleTheme">
        <template v-if="selectedTheme === 'Dark Mode'">
            <font-awesome-icon icon="moon" class="fa-icon" />
        </template>
        <template v-else-if="selectedTheme === 'Light Mode'">
            <font-awesome-icon icon="sun" class="fa-icon" />
        </template>
    </div>
</template>

<script>

import { ref } from 'vue';

export default {
    setup() {
        let theme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'Light Mode';
        document.documentElement.setAttribute('theme', theme);

        const selectedTheme = ref(theme);

        const toggleTheme = () => {
            if (selectedTheme.value === 'Dark Mode') {
                selectedTheme.value = 'Light Mode';
            } else {
                selectedTheme.value = 'Dark Mode';
            }
            localStorage.setItem('theme', selectedTheme.value);
            document.documentElement.setAttribute('theme', selectedTheme.value);
        };

        return {
            toggleTheme,
            selectedTheme,
        };
    },
};

</script>
<style lang="postcss">
.theme-toggle {
    @apply cursor-pointer h-[30px] w-[30px] mt-[3px] mr-[20px] mb-0 ml-20px duration-100;
   
}

.fa-icon {
    color: var(--primary);
    font-size: 22.5px;
    
}

.theme-dropdown {
    @apply cursor-pointer relative;

    .color-selector {
        @apply h-[30px] w-[30px] text-[var(--primary)] mt-[3px] mr-[20px] mb-0 ml-20px duration-100;
    }

    .theme-dropdown-content {
        position: absolute;
        min-width: 18px;
        transform: translateY(30px);
        right: 20px;
        top: 20px;
        padding: 15px 0 0 0;
        opacity: 0;
        z-index: 1;
        transition: 0.1s;
        visibility: hidden;

        .theme-dropdown-content-wrapper {
            padding: 5px 5px;
            display: flex;
            flex-direction: column;
            background-color: var(--lightBackground);
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            border-radius: 8px;
        }
    }

    .color-theme-choices {
        @apply flex items-center p-10px rounded-md;

        .color-selector-pallette {
            display: flex;
            margin: 0 10px 0 0;
        }

        .color-selections {
            @apply h-[15px] w-[15px];
            border-radius: 100%;
            transition: 0.2s;
        }

        &:hover,
        &.active {
            color: var(--primary);
            background-color: rgba(0, 0, 0, 0.3);

            .color-selections {
                border-radius: 0%;
            }
        }
    }

    &.open-dropdown,
    &:focus {
        .color-selector {
            border: 3px solid #fff;
        }

        .theme-dropdown-content {
            transform: translateY(0px);
            opacity: 1;
            visibility: visible;
        }
    }
}
</style>