<template>
    <section v-scrollanimation class="my-work-experience mx-auto">
        <div class="relative">
            <div class="inner">
                <ul class="tab-list">
                    <li v-for="(exp, index) in work_experience" :key="exp.company" @click="changeTab(index)"
                        :class="tab == index ? 'active' : ''">
                        <div>
                            <span class="md:text-size-16px text-size-17">{{ exp.tab }}</span>
                        </div>
                    </li>
                    <div class="slider" :style="width >= 600
                            ? 'transform: translateY(calc(' + tab + ' * var(--tab-height)));'
                            : 'transform: translateX(calc(' + tab + ' * var(--tab-width)));'
                        "></div>
                </ul>

                <template v-for="exp in filteredExperience" :key="exp.company">
                    <div class="company-container">
                        <div class="positions-container">
                            <div v-for="(position, index) in exp.positions" :key="index" v-scrollanimation
                                class="position-details">
                                <p class="range font-420" v-html="position.workStart + ' - ' + position.workUntil"></p>
                                <h3
                                    class="lg:text-size-18px md:text-size-18px text-size-18.5px font-650 items-center gap-1px">
                                    <span>{{ position.position }}</span>
                                    <span class="text-[var(--primary)] opacity-70 hover:opacity-100 duration-100">
                                        @
                                        <a :href="exp.url" target="_blank" rel="noopener">
                                            {{ exp.company }}
                                        </a>
                                    </span>
                                </h3>
                                <div class="mt-20px mb-20px">
                                    <a class="
                                            border
                                            border-[var(--primary)]
                                            rounded-md
                                            px-15px
                                            py-5px
                                            text-[var(--primary)]
                                            hover:bg-[var(--primary)] hover:text-[var(--background)]
                                            duration-300
                                        " v-if="position.certificate" rel="external" target="_blank"
                                        :href="position.certificate.link" hreflang="es-es">
                                        <i class="bx bx-certification"></i>
                                        {{ position.certificate.label }}

                                    </a>
                                </div>
                                <div>
                                    <p class="md:text-size-17px text-size-17px md:leading-normal leading-snug"
                                        v-html="position.des"></p>
                                </div>
                               
                            </div>
                        </div>
                        <div v-if="exp.positions.length > 1" class="progression-line"></div>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>


<script>
import { ref, onUnmounted, onMounted, computed } from 'vue';
import Experience from './experiences/work.js';

const tabKey = 'expTabKey';

export default {

    setup() {
        const tab = ref(0);
        const width = ref(500);

        const widthHandler = () => {
            width.value = window.innerWidth;
        };

        onMounted(() => {
            window.addEventListener('resize', widthHandler);

            widthHandler();
            let tabindex = localStorage.getItem(tabKey);
            if (tabindex) {
                tab.value = parseInt(tabindex);
            }
        });

        onUnmounted(() => {
            window.removeEventListener('resize', widthHandler);
        });

        const filteredExperience = computed(() => {
            return Experience.filter(exp => tab.value === Experience.indexOf(exp));
        });

        return {
            work_experience: Experience,
            tab,
            width,
            filteredExperience,
            changeTab(work) {
                localStorage.setItem(tabKey, work);
                tab.value = work;
            },
        };
    },
};
</script>


<style lang="postcss">
.my-work-experience {
    

    --tab-height: 50px;
    --tab-width: 120px;
    margin-bottom: 0;
    padding-bottom: 0;

    max-width: 1100px;
    display: flex;
    flex-direction: column;
    min-height: 38vh;

    &.a-before-enter {
        opacity: 0;
        transform: translateY(20px);
        transition: 0.3s ease-in;
    }

    &.a-enter {
        opacity: 1;
        transform: translateY(0);
    }

    .inner {
        display: flex;
        gap: 20px;

        .tab-list {
            position: relative;
            z-work: 3;
            width: max-content;
            padding: 0px;
            margin: 20px 0 0 0;
            list-style: none;

            li {
                height: var(--tab-height);

                div {
                    text-decoration: none;
                    text-decoration-skip-ink: auto;
                    position: relative;
                    transition: var(--transition);
                    cursor: pointer;
                    display: flex;
                    -webkit-box-align: center;
                    align-items: center;
                    height: var(--tab-height);
                    padding: 0px 20px 2px;
                    border-left: 2px solid var(--lightBackground);
                    background-color: transparent;
                    color: var(--slate);
                    font-family: var(--font-mono);
                    text-align: left;
                    white-space: nowrap;
                    font-size: 0.8rem;
                }

                &.active {
                    background-color: var(--lightBackground);

                    div {
                        color: var(--primary);
                    }
                }

                &:hover {
                    background-color: var(--lightBackground);
                }
            }

            .slider {
                position: absolute;
                top: 0px;
                left: 0px;
                z-work: 10;
                width: 2px;
                height: var(--tab-height);
                border-radius: var(--border-radius);
                background: var(--primary);
                transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
            }
        }

        .company-details {
            width: 100%;
            height: auto;
            padding-left: 30px;
            padding-top: 20px;

            p.range {
                .present-work {
                    @apply text-[var(--primary)];
                    
                }
            }

            &.a-before-enter {
                opacity: 0;
                transform: translateY(20px);
                transition: 0.5s;
            }

            &.a-enter {
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .my-work-experience {
        .inner {
            display: block;

            .tab-list {
                display: flex;
                overflow-x: auto;
                overflow-y: hidden;
                width: calc(100% + 50px);
                margin-left: -50px;
                margin-bottom: 0px;

                li:first-of-type {
                    margin-left: 50px;
                }

                li {
                    div {
                        display: flex;
                        -webkit-box-pack: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        align-items: center;
                        min-width: 90px;
                        padding: 0px 15px;
                        border-left: 0px;
                        border-bottom: 2px solid var(--lightest-navy);
                        text-align: center;
                    }
                }

                .slider {
                    top: auto;
                    bottom: 0px;
                    width: 100%;
                    max-width: var(--tab-width);
                    height: 2px;
                    margin-left: 50px;
                }
            }

            .company-details {
                @apply pl-0;
            }
        }
    }
}
</style>






