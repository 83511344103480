<template>
    <section v-scrollanimation class="about-me mx-auto">
        <div class="about-me-inner flex flex-row items-center gap-[50px] relative">
            <div class="about-me-text">
                <div class="about-me-text-info text-right max-w-500px">
                    <div>
                        <div
                            class="lg:text-size-43px md:text-size-43px text-size-36px font-600 text-[var(--primary)] tracking-tight mt-0px">
                            Hamza El Alaoui</div>
                        <div
                            class="text-size-34px md:text-size-28px text-size-28px font-600 text-[var(--lightestSlate)] tracking-tight mt-13px">
                            <!-- <TypeWriter> </TypeWriter> --> Research Engineer
                        </div>
                    </div>
                </div>
            </div>
            <div class="about-me-photo z-50">
                <div class="wrapper">
                    <div class="photo" style="position: relative; overflow: hidden">
                        <div class="photo-front"></div>
                        <img class="max-w-250px w-[100%] w-[300px] profile-picture-style" loading="lazy"
                            src="./../../assets/images/profileImage.png" alt="Hamza El Alaoui - Picture" />
                    </div>
                </div>
            </div>
            <SvgDecoration
                classNames="absolute md:visible invisible left-[-180px] bottom-[-20px] transform rotate-[-360deg] fill-[var(--primary)] opacity-50"
                :type="1" />
            <SvgDecoration classNames="absolute md:right-[-56px] md:visible invisible z-20 fill-[var(--primary)] opacity-50"
                :type="2" />
        </div>
        <div
            class="md:text-size-20px text-size-20px md:px-0px w-[100%] max-w-[1060px] pt-40px md:leading-relaxed leading-relaxed relative">
            <div class="mb-30px">

                <h2 class="header-style" ref="bioHeader" style="color: var(--primary); font-size: 23px;">Bio</h2>
                <div class="header-shadow-line" :style="{ width: BioHeaderWidth }"></div>


                <p class="bio-desc">
                    I am an MTS at <a href="https://labs.oracle.com" target="_blank"
                        rel="noopener noreferrer">Oracle Labs</a>, the dedicated research wing of Oracle, where I
                    apply Machine Learning to enhance both Oracle-internal products and those serving our global
                    customer base.
                    I hold a B.S. in Computer Science from Al Akhawayn University. Driven by the aspiration of <i>Empowering a Brighter World
                        with AI</i>, my passion lies in creating impactful technological innovations that have the potential
                    to improve lives. Outside of my professional endeavors, I enjoy cycling,
                    exploring nature, home decor, cooking, and gaming.  
                </p>




                <br>

                <h2 class="header-style" ref="ResearchHeader" style="color: var(--primary); font-size: 23px;">Research
                    Interests</h2>
                <div class="header-shadow-line" :style="{ width: ResearchHeaderWidth }"></div>

                <ul class="interests-list">


                    <li>
                        <span class="interest-title">Intelligent Tutoring Systems:</span>
                        <span class="interest-desc">Exploring computer systems that offer tailored learning experiences, adapting dynamically to suit each learner's unique needs.</span>
                    </li>


                   <li>
                  <span class="interest-title">Human-Computer Interaction:</span>
                     <span class="interest-desc">Exploring the symbiotic relationship between users and digital platforms, aiming to create intuitive, efficient, and accessible technologies that seamlessly integrate into the learning experience.</span>
                     </li>


                 

                     <li>
                 <span class="interest-title">Conversational & Virtual Agents:</span>
                        <span class="interest-desc">Delving into the nuances of AI-powered conversational entities to enhance educational experiences, facilitating human-like dialogues and immersive interactions for diverse educational purposes.</span>
                    </li>


<li>
    <span class="interest-title">Inclusive Design:</span>
    <span class="interest-desc">Designing technology that understands and enables people of all backgrounds and abilities. Every design choice underscores representation, inclusivity, and fostering a sense of belonging for everyone.</span>
</li>





                </ul>
                <br>


                <h2 class="header-style" ref="OtherHeader" style="color: var(--primary); font-size: 23px;">Other Interests
                </h2>
                <div class="header-shadow-line" :style="{ width: OtherHeaderWidth }"></div>



                <ul class="interests">
                    <li>
                        <span class="interest-title">Games Development:</span>
                        <span class="interest-desc">As a former Esports professional, I possess a unique perspective on game mechanics, balance, and development, allowing for a deeper understanding of player needs and motivations.</span>
                    </li>
                    
                    
                    <li>
                        <span class="interest-title">AI for Sustainability:</span>
                        <span class="interest-desc"> Leveraging data and computing to mitigate environmental impacts, enhance energy efficiency, and safeguard our ecosystem and habitats.</span>
                        
                    </li>




                </ul>
                <br>

                <h2 class="header-style" ref="WorkHeader" style="color: var(--primary); font-size: 23px;">Experience</h2>
                <div class="header-shadow-line" :style="{ width: WorkHeaderWidth }"></div>
                <WorkExperience />


                <h2 class="header-style" ref="NewsHeader" style="color: var(--primary); font-size: 23px;">News</h2>
                <div class="header-shadow-line" :style="{ width: NewsHeaderWidth }"></div>


                <div class="timeline-wrapper" style="font-size: 18px;">

                    <p>
                        <Timeline> </Timeline>
                    </p>
                </div>


            </div>


            <SvgDecoration
                classNames="absolute right-[-120px] top-209 md:block hidden z-20 fill-[var(--primary)] opacity-50"
                :type="3" />
        </div>
    </section>
</template>


<script>
import SvgDecoration from './../SvgDecoration/SvgDecoration.vue';
// import TypeWriter from './../AboutMe/TypeWriter.vue';
import Timeline from './Timeline.vue';
import WorkExperience from '../.././views/MyExperience/work_page.vue'

export default {


    components: { SvgDecoration, Timeline, WorkExperience }, // TypeWriter
    setup() {
        const BioHeaderWidth = '3.2%'
        const ResearchHeaderWidth = '17.6%'
        const OtherHeaderWidth = '14.4%'
        const NewsHeaderWidth = '5.5%'
        const WorkHeaderWidth = '10.7%'

        return {
            BioHeaderWidth, ResearchHeaderWidth, OtherHeaderWidth, NewsHeaderWidth, WorkHeaderWidth,

            yearCount: () => {
                return new Date().getFullYear() - 2017;
            }
        };
    },
};
</script>

<style lang="postcss">
.about-me {
    @apply flex flex-col items-center min-h-[100vh] w-[100%] visible opacity-100 z-10 px-10px lg: mt-130px;

    &.a-before-enter {
        @apply opacity-0 transform translate-y-[20px] duration-[0.3s] ease-in;
    }

    &.a-enter {
        @apply opacity-100 transform translate-y-0;
    }

    .about-me-photo {
        position: relative;

        .wrapper {
            @apply overflow-hidden rounded-lg;
        }
    }
}

.header-shadow-line {
    @apply mt-0.24 mb-5 h-1px bg-[var(--primary)] opacity-50;
}


.timeline-wrapper {
    width: var(--timeline-width, 100%);
    margin-left: var(--timeline-margin-left, 0px);
    margin-right: var(--timeline-margin-right, 0px);
}

@media only screen and (max-width: 856px) {
    .about-me {
        .about-me-text-info {
            @apply text-center;
        }

        .about-me-inner {
            @apply flex flex-col gap-[50px];

            .about-me-photo {
                @apply order-1 self-center;
            }

            .about-me-text {
                @apply order-2 self-center;
            }
        }
    }
}

.is-typed span.cursor {
    display: inline-block;
    width: 3px;
    background-color: #0a192f;
    animation: blink 1s infinite;
}

.is-typed span.underscore {
    display: inline-flex;
    width: 10px;
    height: 1px;
    align-items: flex-end;
    background-color: rgb(0, 0, 0);
    animation: blink 1s infinite;
}

.is-typed span.cursor.typing {
    animation: none;
}

.interests,
.interests-list {
    list-style: none;
    padding-left: 0;
}

.interests li,
.interests-list li {
    margin-bottom: 1rem;
}

.interest-title,
.interest-title {
    color: var(--primary);
    font-weight: 645;
    margin-right: 0.5rem;
    font-size: 19px;
}

.interest-desc {
    color: var(--lightestSlate);
    font-size: 19px;
}

.bio-desc {
    color: var(--lightSlate);
    font-size: 19px;
}



@keyframes blink {
    49% {
        background-color: #d8d8d8;
    }

    50% {
        background-color: transparent;
    }

    99% {
        background-color: transparent;
    }
}</style>