<template>
  <div class="timeline-container">
    <ul class="timeline">
      <li v-for="(milestone, index) in displayedMilestones" :key="index">
        <div class="date">{{ formatDate(milestone.date) }}</div>
        <div class="description" v-html="formattedDescription(milestone.description)"></div>
      </li>
    </ul>
    <div v-if="!showAll && milestones.length > 6" class="view-toggle" @click="expandTimeline">View More</div>
    <div v-if="showAll && milestones.length > 6" class="view-toggle" @click="collapseTimeline">View Less</div>
  </div>
</template>

<script>
import milestonesData from './milestones.json';

export default {
  data() {
    return {
      milestones: milestonesData.sort((a, b) => new Date(b.date) - new Date(a.date)),
      showAll: false
    };
  },
  computed: {
    displayedMilestones() {
      return this.showAll ? this.milestones : this.milestones.slice(0, 6);
    }
  },
  methods: {
    expandTimeline() {
      this.showAll = true;
    },
    collapseTimeline() {
      this.showAll = false;
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    },
    formattedDescription(description) {
      return description.replace(/\[\[(.*?)\|(.*?)\]\]/g, '<a href="$1" target="_blank" class="description-link">$2</a>');
    }
  }
};
</script>


<style scoped> 

.timeline {
  list-style: none;
  padding-left: 0;
}

.timeline li {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 10px 0;
  border-bottom: 1px solid var(--lightestSlate);
}

.date {
  font-weight: 350;
  background-color: var(--lightBackground);
  padding: 6.2px;
  border-radius: 5px;
  font-size: 18px;
  color: var(--primary);
  border: 2px solid var(--primary); 

   
}

.description {
  margin-left: 17px;
  flex: 1;
  font-size: var(--text-size-36px);
  color: var(--lightestSlate);
  font-weight: 400;
  line-height: 1.3; 
}

.description a {
  color: var(--primary);
  text-decoration: none;
}

.view-toggle {
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
  font-size: var(--text-size-36px);
  color: var(--primary);
}
</style>
