<template>
    <div class="header" :class="{ 'navbar--hidden': !showNavbar && scroll > 100, 'nav-min': scroll > 50 }">
        <div v-scrollanimation style="transition-delay: 900ms">
            <ThemeChanger />
        </div>
        <nav>
            <div class="navs">
                <div class="menu-items">
                    <ol>
                
                        <li v-scrollanimation style="transition-delay: 100ms" @click="$router.push({ name: 'AboutMe' })">
                            <div class="flex items-center" :class="{ active: $route.name == 'AboutMe' }">
                                <span class="text-size-14px font-500">Home </span>
                            </div>
                        </li>
                        <li v-scrollanimation style="transition-delay: 550ms" @click="$router.push({ name: 'Research' })">
                            <div :class="{ active: $route.name == 'Research' }">
                                <span class="text-size-14px font-500">Research</span>
                            </div>
                        </li>
                    </ol>
                </div>
               
            </div>
            <div class="show-mobile-nav-but">
                <Icon name="menu" :size="30" @click="$store.state.navShow = true" style="margin-right: 20px" />
                
            </div>
            <div class="resume-container">
                    <a href="https://drive.google.com/file/d/1bCSjC7R1s3nPdjnWBp_iVRTwz2W77gfr/view" target="_blank"
                        class="bg-[var(--social-icon-light-color)] px-9px py-6.3px rounded-md text-[var(--resume-button-txt)] font-450 opacity-90 hover:opacity-100">
                        Resume
                    </a>
                </div>
        </nav>
    </div>
</template>

<script>
import ThemeChanger from './../themeChanger/themeChanger';
import Icon from './../../components/Icons';

export default {
    name: 'Header',
    components: { Icon, ThemeChanger },
    data() {
        return {
            scroll: 0,
            width: 0,
            showNavbar: true,
            lastScrollPosition: 0,
        };
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleWidth);
    },
    methods: {
        goToLink(link) {
            window.open(link);
        },
        handleScroll() {
            this.scroll = window.scrollY;
        },
        handleWidth() {
            this.width = window.innerWidth;
        },
        onScroll() {
            const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
            if (currentScrollPosition < 0) {
                return;
            }
            this.showNavbar = currentScrollPosition < this.lastScrollPosition;
            this.lastScrollPosition = currentScrollPosition;
        },
    },
    mounted() {
        window.addEventListener('scroll', this.onScroll);
    },
    beforeUmount() {
        window.removeEventListener('scroll', this.onScroll);
    },
};
</script>


<style lang="scss" scoped>
.navs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 10px;  
}

.menu-items li span {
    font-size: 17px;  
    font-family: 'Arial', sans-serif;  
}
.resume-container a {
    font-size: 16px; 
    font-family: 'Arial', sans-serif;  
    margin-right: 16px;
  
}


.header {
    min-height: 65px
}

</style>