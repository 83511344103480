import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import './assets/main.scss';
import './registerServiceWorker';
import ScrollAnimation, { animationViewFull } from './directives/scrollAnimation';
import { store } from './store/store';
import VueSession from './helper/VueSession';
import 'boxicons/css/boxicons.min.css';
import VueWriter from "vue-writer";
import 'windi.css';

library.add (faSun, faMoon);

createApp(App)
    .directive('scrollanimation', ScrollAnimation)
    .directive('scrollview', animationViewFull)
    .directive('click-outside', {
        beforeMount(el, binding) {
            el.clickOutsideEvent = function (event) {
                if (!(el === event.target || el.contains(event.target))) {
                    binding.value(event, el);
                }
            };
            document.body.addEventListener('click', el.clickOutsideEvent);
        },
        unmounted(el) {
            document.body.removeEventListener('click', el.clickOutsideEvent);
        }
    })
    .use(store)
    .use(router)
    .use(VueSession, {
        persist: true
    })
    .use(VueWriter)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')

