<template>
    <div class="social-network">
        <div class="social-media social-media-orientation social-media-placement">
            <ul v-scrollanimation class="social-media-list">
        
         <!-- Comment  
                   <li>
                    <div @click="openSite('https://www.linkedin.com/in/hamzaelalaoui/')" class="a-link">
                        <div class="text-size-25px"><i class="bx bxl-linkedin"></i></div>
                        <span>LinkedIn</span>
                    </div>
                </li>
 -->
                

                <li>
                    <div @click="openSite('https://scholar.google.com/citations?user=9PBfhTkAAAAJ&hl=en')" class="a-link">
                        <div class="text-size-25px"><i class="bx bxl-google"></i></div>
                        <span>Google Scholar</span>
                    </div>
                </li>     

                
                <li>
                    <div @click="openSite('https://github.com/hamzaeIalaoui')" class="a-link">
                        <div class="text-size-25px"><i class="bx bxl-github"></i></div>
                        <span>GitHub</span>
                    </div>
                </li>

 
                
           
                 <li>
                    <div @click="openSite('https://www.youtube.com/@hamzaeIalaoui')" class="a-link">
                        <div class="text-size-25px"><i class="bx bxl-youtube"></i></div>
                        <span>Youtube</span>
                    </div>
                </li>   


        <!-- Comment  
                <li>
                    <div @click="openSite('https://www.facebook.com/hamzaeIalaoui')" class="a-link">
                        <div class="text-size-25px"><i class="bx bxl-facebook"></i></div>
                        <span>Facebook</span>
                    </div>
                </li>
        -->


            </ul>
        </div>
        <div v-scrollanimation class="email-media social-media-orientation social-media-placement">
            <div class="email-media-link">
                <div class="mail-link">hamzaelalaoui[at]ieee[dot]org</div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity';
export default {
    name: 'SocialMediaLinks',
    setup() {
        return {
            show: ref(true),
            openSite(site) {
                window.open(site, '_blank');
            },
        };
    },
};
</script>