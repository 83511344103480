const AUI = {
    company: 'Al Akhawayn University',
    tab: 'Al Akhawayn University',
    url: "http://www.aui.ma/en/sse.html",
    positions: [
       
        {
            position: 'Research Assistant',
            workStart: 'Oct. 2021',
            workUntil: 'May. 2023',
            des: "Engaged in research spanning NLP for education, autonomous driving, and sustainable energy management, yielding numerous publications and notable achievements. <br><br>▪ Developed a multimodal language-learning chatbot.<br>▪ Contributed to BMZ & DAAD-supported sustainable energy projects.<br>▪ Assisted a Ph.D. student in autonomous driving research.<br>▪ Co-authored multiple articles and presented at several conferences. <br>▪ Received the Student Research Mobility Grant. <br>▪ Awarded the Best Undergraduate Researcher Award."
        }
    ]
}

const Mastercard = {
    company: 'Mastercard',
    tab: 'Mastercard',
    url: "https://mastercard.com/",
    positions: [
        {
            position: 'Software Engineer Intern',
            workStart: 'Jun. 2022',
            workUntil: 'Sep. 2022',
            des:  "Worked on improving the Customer Technical Services ticketing software. <br> <br> ▪ Conducted a requirement specification and analysis for the software. <br>▪ Analyzed and cleaned data from various MC clients, extracting valuable insights. <br> ▪ Developed a tool, saving ≈ 6 minutes per Level-2 Support case."
        }
    ]
}

const Prodware = {
    company: 'Prodware',
    tab: 'Prodware',
    url: "https://www.prodwaregroup.com/en-gb/",
    positions: [
        {
            position: 'Computer Vision Intern',
            workStart: 'Jun. 2021',
            workUntil: 'Sep. 2021',
            des:  
            "Developed an ANPR application for Moroccan vehicle plates.<br> <br>▪ Used YOLOv3 for vehicle plate detection and fine-tuned an OCR model to recognize Moroccan number plate characters. <br>  ▪ Built a user-friendly desktop application using the PyQt framework."
      
      
      
        }
    ]
}

const Oracle = {
    company: 'Oracle Labs',
    tab: 'Oracle',
    url: "https://labs.oracle.com/",

    positions: [

        {
            position: 'Member of Technical Staff',
            workStart: 'Oct. 2023',
            workUntil: 'Present',
            des: "Working within the Automated Machine Learning with Explainability (AutoMLx) team, applying Machine Learning to enhance both Oracle-internal products and those serving our global customer base.<br><br>"
        },
        {
            position: 'Research Assistant',
            workStart: 'Jun. 2023',
            workUntil: 'Sep. 2023',
            des: "Used Large Language Models and NLP techniques to simplify log search.",
        }

    ]
}


export default [Oracle, AUI, Mastercard, Prodware]